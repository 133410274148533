::-webkit-scrollbar {
    width: 0px;
}


@media screen and (max-width: 380px){
    .myurl_header_container {
        top: 12.5% !important;
    }
}
/* @media screen and (min-width: 1025px){
    .myurl_header_container {
        width: 69% !important;
    }
} */
.myurl_header_wrap {
    display: inline-block;
    position: sticky;
    top: 0px;
    z-index: 999;
    background-color: antiquewhite;
}

.myurl_header_title {
    float: left;
    margin-left: 6px;
    font-weight: 500;
    font-size: 16px;
    margin-top: 13px;
}

.myurl_header_close_btn {
    float: right;
    margin-top: 10px;
}

.dialog_item {
    overflow: hidden;
    position: relative;
}

.dialog_item_sidebar {
    float: left;
    background-color: #01bf71;
    position: absolute;
    height: 100%;
    width: 2px;
}

.dialog_item_link {
    margin-left: 7px;
}

.dialog_item_link_key {
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.dialog_item_link_value {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #809a32!important;
    -webkit-line-clamp: 2;
    max-height: 3em;
    font-size: 80%;
    font-weight: 400;
}

.dialog_item_meta_data {
    display: flex!important;
}

.dialog_item_meta_data_time {
    align-self: center!important;
    color: #6c757d!important;
    text-align: right!important;
    font-size: 11px;
}

.dialog_item_meta_data_btn {
    margin-left: auto!important;
}

.dialog_item_meta_data_btn_open {
    cursor: pointer;
    margin-right: 4px;
}

.dialog_item_meta_data_btn_open_link {
    text-decoration: none;
    font-size: .8125rem;
    color: #087da8;
    padding: 3px 16px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    border: 0.5px solid #087da8;
    margin-left: -1px;
    -webkit-appearance: button;
    margin: 0;
    border-radius: 12px;
}

.dialog_item_meta_data_btn_copy {
    cursor: pointer;
    font-weight: 500;
    font-size: .8125rem;
}

.dialog_item_meta_data_btn_copy_btn {
    padding: 5px 16px;
    color: #fff;
    background-color: #01bf71;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    border: 0;
    margin-left: -1px;
    -webkit-appearance: button;
    margin: 0;
    font-weight: 200;
    border-radius: 12px;
}

.dd_credit {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #01bf71;
}

a {
    text-decoration: none;
    color: #01bf71;
}

.heart {
    color: red;
    margin-right: 2px;
}

.myurl_header_container {
    width: 91%;
}

.support_us_container {
    text-align: center;
    display: flex;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

.support_us_wrapper {
    margin: auto;
}

.support_us_link {
    text-decoration: none;
}

.support_us_img {
    width: 42%;
    padding-top: 5px;
    border-radius: 22px;
}