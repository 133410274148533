
  .form-container {
    width: 100%;
    position: absolute;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  }
  
  .home__inputContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
  }
  
  .short-input {
    padding: 16px 16px;
    margin-bottom: 13px;
    border: none;
    border-radius: 4px;
    width: 100%;
  }
  
  .button-wrap {
    width: 100%;
    text-align: end;
  }
  
  .short-submit {
    background: var(--app-primary-color);
    padding: 10px 10px;
    border: none;
    border-radius: 4px;
    color: var(--app-secondary-color);
    font-size: 16px;
    cursor: pointer;
    margin-left: 2%;
    width: 30%;

  }
  .short-submit:hover {
      transition: all 0.2s ease-in-out;
      background-color: bisque;
  }


  .result-container {
    margin-top: 20px;
  }

  .result-section {
    margin: 0 auto 20px auto;
    max-width: 100%;
    box-shadow: 0 1px 4px #ccc;
    border-radius: 2px;
    padding: 10px 10px 10px;
    background: #fff;
    text-align: center;
    border-radius: 12px;
  }

  .shortlink__wrap {
    max-width: 100%;
    display: flex;
  }
  
  .shortlink__wrap__input {
    display: table-cell;
    width: 100%;
    padding: 0px 6px;
    font-size: 13px;
    color: var(--app-secondary-color);
    background: #fff;
    border: 0;
    font-weight: 600;
  }

  .button__input {
    padding: 10px 16px;
    color: #fff;
    background-color: #01bf71;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    white-space: nowrap;
    border: 0;
    margin-left: -1px;
    -webkit-appearance: button;
    margin: 0;
    font-weight: 200;
    border-radius: 12px;
  }

  .shortlink-input {
    width: 80%;
    box-sizing: border-box;
  }

  .shortlink-button {
    width: 20%;
    background-color: #01bf71;
    border-radius: 12px;
  }

 .textarea_input {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}


@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.shortlink_qr_code_wrap {
  display: flex;
}

.shortlink_qr_code {
  margin: auto;
}

.shortlink_qr_code_img {
  box-shadow: 0 1px 4px #ccc;
  border-radius: 12px;
}

.short_error {
  color: red;
  font-weight: 400;
  font-size: 15px;
}

.support_us_container {
    text-align: center;
    display: flex;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

.support_us_wrapper {
    margin: auto;
}

.support_us_link {
    text-decoration: none;
}

.support_us_img {
    width: 50%;
    padding-top: 5px;
    border-radius: 22px;
}